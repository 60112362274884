/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridSortCellParams,
} from "@mui/x-data-grid";
import { formatDate } from "@/utils/formatDate";
import { RenewalOfferDecisionType } from "@/graphql/generated";
import { Body1 } from "@/components/Typography";
import ToolTip from "@/components/Tooltip";
import * as Styled from "./styles";
import { determineNeedSomethingElseCopy } from "./logic";
import { RowType } from "./types";

type DeterminedStatusRenewalOffer = RowType["determinedStatus"];
type DecisionRenewalOffer = RowType["decision"];

const getDecisionInfo = (value: DecisionRenewalOffer) => {
  const input: { decision: string; info?: string } = {
    decision: "No decision made",
  };

  const monthValue =
    value.interestedTerm?.lengthInMonths &&
    value.interestedTerm?.baseRent &&
    value.decidedAt &&
    value.interestedTerm?.lengthInMonths > 0
      ? `${value.interestedTerm?.lengthInMonths}/mo lease - $${
          value.interestedTerm?.baseRent
        }/mo - Submitted on ${formatDate(new Date(value.decidedAt))}`
      : value.decidedAt &&
        `Month to month - Submitted on ${formatDate(
          new Date(value.decidedAt)
        )}`;

  if (
    value?.type === RenewalOfferDecisionType.Interested ||
    value?.type === RenewalOfferDecisionType.InterestedLate
  ) {
    input.decision = "Accepted";
    input.info =
      value.interestedTerm?.lengthInMonths &&
      value.interestedTerm?.baseRent &&
      value.decidedAt
        ? monthValue
        : undefined;
  } else if (value?.type === RenewalOfferDecisionType.Declined) {
    input.decision = "Notice to vacate";
    // if there is no move out date, it means the resident soft declined
    // we will not show a move out date with the NTV decision
    if (value.decidedAt && !value.moveOutInfo.moveOutDate) {
      input.info = `Submitted on ${formatDate(new Date(value.decidedAt))}`;
    } else if (value.decidedAt && value.moveOutInfo.moveOutDate) {
      input.info = `Move out date ${formatDate(
        new Date(value.moveOutInfo.moveOutDate)
      )} - Submitted on ${formatDate(new Date(value.decidedAt))}`;
    }
  } else if (value?.unitUpgrade?.createdAt) {
    input.decision = "Upgrade request";
    input.info = `Unit ${
      value.unit?.building ? `${value.unit.building} - ` : ""
    }${value.unitUpgrade.unit?.marketingName} - Submitted on ${formatDate(
      new Date(value.unitUpgrade.createdAt)
    )}`;
  }

  return input;
};

const renderDecisionInfoCell = (
  params:
    | GridCellParams<DecisionRenewalOffer>
    | GridSortCellParams<DecisionRenewalOffer>
) => {
  const { value } = params;
  const input = getDecisionInfo(value as DecisionRenewalOffer);

  return {
    sortValue: `${input.decision} ${input.info || ""}`,
    returnValue: (
      <div>
        <Body1 $size="xs" $weight="medium">
          {input.decision}
        </Body1>
        {input.info && <Body1 $size="2xs">{input.info}</Body1>}
      </div>
    ),
  };
};

const renderActionNeededCell = (
  params:
    | GridRenderCellParams<DeterminedStatusRenewalOffer>
    | GridSortCellParams<DeterminedStatusRenewalOffer>
) => {
  const { value } = params as { value: DeterminedStatusRenewalOffer };
  if (!value) {
    return {
      sortValue: "Renewal offers sent",
      returnValue: "Renewal offers sent",
    };
  }
  switch (true) {
    case value.decisionType === RenewalOfferDecisionType.Interested ||
      value.decisionType === RenewalOfferDecisionType.InterestedLate:
      return {
        sortValue: "Renewal complete! ",
        returnValue: "Renewal complete! ",
      };
    case value.workflowStatus === "COMPLETED": {
      const decisionMadeDate = value.decidedAt ? (
        <Body1 $size="2xs">
          Decision made {formatDate(new Date(value.decidedAt))}
        </Body1>
      ) : null;

      const content =
        value.decisionType === RenewalOfferDecisionType.Declined
          ? "Notice to vacate complete!"
          : "Renewal complete!";

      return {
        sortValue: content,
        returnValue: (
          <div>
            <Body1 $size="xs" $weight="medium">
              {content}
            </Body1>
            {decisionMadeDate}
          </div>
        ),
      };
    }
    case Boolean(value.eSignature) &&
      value.decisionType === RenewalOfferDecisionType.Declined: {
      switch (value?.eSignature?.status) {
        // when all residents have signed but not partner, show action chip to sign
        case "ALL_RESIDENTS_SIGNED":
          return {
            sortValue: "Counter-sign: Notice to vacate",
            returnValue: (
              <Styled.StatusPillWrapper
                href={
                  value.unitId &&
                  `/units/${value.unitId}/countersign?source=renewal-offers`
                }
              >
                <ToolTip popperContent="You must counter-sign resident documents to complete lease">
                  <Styled.StatusPill $type="actions">
                    <Styled.InfoIcon />
                    Counter-sign: Notice to vacate
                  </Styled.StatusPill>
                </ToolTip>

                {value.mostRecentNeedSomethingElseRequest && (
                  <Styled.AttachEmailIconStandaloneWrapper>
                    <ToolTip
                      popperContent={determineNeedSomethingElseCopy(
                        value.mostRecentNeedSomethingElseRequest
                      )}
                    >
                      <Styled.AttachEmailIcon />
                    </ToolTip>
                  </Styled.AttachEmailIconStandaloneWrapper>
                )}
              </Styled.StatusPillWrapper>
            ),
          };
        // in any other case show awaiting resident NTV sign
        default:
          return {
            sortValue: "Awaiting resident NTV sign",
            returnValue: "Awaiting resident NTV sign",
          };
      }
    }
    case Boolean(value.mostRecentNeedSomethingElseRequest):
      return {
        sortValue:
          value.mostRecentNeedSomethingElseRequest &&
          determineNeedSomethingElseCopy(
            value.mostRecentNeedSomethingElseRequest
          ),
        returnValue: (
          <Styled.StatusPill $type="messages">
            <Styled.AttachEmailIcon />
            {value.mostRecentNeedSomethingElseRequest &&
              determineNeedSomethingElseCopy(
                value.mostRecentNeedSomethingElseRequest
              )}
          </Styled.StatusPill>
        ),
      };
    case Boolean(value.lastViewedAt):
      return {
        sortValue: `Awaiting resident decision Renewal offer viewed last on  ${formatDate(
          new Date(value.lastViewedAt || "")
        )}`,
        returnValue: (
          <div>
            <Body1 $size="xs" $weight="medium">
              Awaiting resident decision
            </Body1>
            <Body1 $size="2xs">
              Renewal offer viewed last on{" "}
              {value.lastViewedAt && formatDate(new Date(value.lastViewedAt))}
            </Body1>
          </div>
        ),
      };
    default:
      return {
        sortValue: "Renewal offers sent",
        returnValue: "Renewal offers sent",
      };
  }
};

export const columns: GridColDef[] = [
  {
    field: "unit",
    headerName: "Unit #",
    headerClassName:
      "renewalOffer-theme--header renewalOffer-theme--header__link",
    cellClassName: "renewalOffer-theme--cell renewalOffer-theme--cell__link",
    headerAlign: "left",
    filterable: true,
    width: 186,
    sortComparator: (_v1, _v2, param1, param2) =>
      (param1.value.building
        ? `${param1.value.building as string} - ${param1.value.name as string}`
        : param1.value.name
      ).localeCompare(
        param2.value.building
          ? `${param2.value.building as string} - ${
              param2.value.name as string
            }`
          : param2.value.name
      ),
    align: "left",
    renderCell: (params: GridCellParams<RowType>) => (
      <div>
        <Styled.CellLink href={`/units/${params.row.unit?.id as string}`}>
          {params.row.unit?.building && `${params.row.unit?.building} - `}
          {params.row.unit?.name}
        </Styled.CellLink>
        <Body1 $size="2xs">{params.row.unit?.communityName}</Body1>
      </div>
    ),
  },
  {
    field: "determinedStatus",
    headerName: "Status",
    headerClassName:
      "renewalOffer-theme--header renewalOffer-theme--header__link",
    cellClassName: "renewalOffer-theme--cell renewalOffer-theme--cell__link",
    headerAlign: "left",
    filterable: true,
    width: 292,
    align: "left",
    sortComparator: (_v1, _v2, param1, param2) =>
      (
        renderActionNeededCell(param1).sortValue?.toString() || ""
      ).localeCompare(
        renderActionNeededCell(param2).sortValue?.toString() || ""
      ),
    renderCell: (params) => renderActionNeededCell(params).returnValue,
  },
  {
    field: "decision",
    headerName: "Resident Decision",
    headerClassName: "renewalOffer-theme--header",
    cellClassName: "renewalOffer-theme--cell",
    headerAlign: "left",
    filterable: false,
    width: 355,
    align: "left",
    sortComparator: (_v1, _v2, param1, param2) =>
      (
        renderDecisionInfoCell(param1).sortValue?.toString() || ""
      ).localeCompare(
        renderDecisionInfoCell(param2).sortValue?.toString() || ""
      ),
    renderCell: (params) => renderDecisionInfoCell(params).returnValue,
  },
  {
    field: "lease",
    headerName: "Notice date",
    cellClassName: "renewalOffer-theme--cell",
    headerClassName: "renewalOffer-theme--header",
    headerAlign: "left",
    filterable: false,
    width: 219,
    align: "left",
    sortComparator: (_v1, _v2, param1, param2) =>
      (!param1.value.toDate
        ? "Month to month"
        : param1.value?.noticeDate?.toString() || ""
      ).localeCompare(
        !param2.value.toDate
          ? "Month to month"
          : param2.value?.noticeDate?.toString() || ""
      ),
    renderCell: (params: GridCellParams<RowType>) => {
      if (params.row.lease?.noticeDate) {
        return formatDate(params.row.lease.noticeDate);
      }
      if (!params.row.lease.toDate) {
        return "Month to month";
      }
      return "";
    },
  },
  {
    field: "leaseToDate",
    headerName: "Lease end date",
    cellClassName: "renewalOffer-theme--cell",
    headerClassName: "renewalOffer-theme--header",
    headerAlign: "left",
    filterable: false,
    width: 219,
    align: "left",
    sortComparator: (_v1, _v2, param1, param2) =>
      (!param1.value
        ? "Month to month"
        : param1.value?.toDate?.toString() || ""
      ).localeCompare(
        !param2.value ? "Month to month" : param2.value?.toString() || ""
      ),
    renderCell: (params: GridCellParams<RowType>) => {
      if (params.row.leaseToDate) {
        return formatDate(params.row.leaseToDate);
      }
      return "Month to month";
    },
  },
];
