import defaultTheme from "@/styles/themes/default";
import styled from "@emotion/styled";

export const ToolTipWrapper = styled.div`
  cursor: pointer;
  display: inline;
  position: relative;
  color: ${defaultTheme.renewTheme.colors.night400};
  font-weight: ${defaultTheme.renewTheme.weights.medium};
`;

export const tooltipStyles = {
  "& .MuiTooltip-tooltip": {
    backgroundColor: defaultTheme.renewTheme.colors.white,
    fontSize: defaultTheme.renewTheme.spacing.mobile["2xs"],
    lineHeight: "1.125rem",
    borderRadius: "1rem",
    boxShadow:
      "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
    opacity: 0,
    padding: defaultTheme.renewTheme.spacing.mobile["2xs"],
    color: defaultTheme.renewTheme.colors.grey600,
    width: "fit-content",
    fontFamily: "Mint Grotesk",
    fontWeight: defaultTheme.renewTheme.weights.regular,
    right: 0,
    left: 100,
    marginTop: 0,
  },
};
