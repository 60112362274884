import { MouseEventHandler } from "react";
import * as Styled from "./styles";

const Pill = ({
  label,
  onDelete,
  onClick,
  className,
  variant = "filled",
  small = false,
}: {
  label?: string;
  onDelete?: (() => void) | undefined;
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
  variant?: "filled" | "outlined";
  small?: boolean;
}) => (
  <Styled.Button
    $small={small}
    $filled={variant === "filled"}
    className={className}
    onClick={onClick}
    label={label}
    onDelete={onDelete}
    variant={variant}
  />
);

export default Pill;
