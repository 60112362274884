import { RenewalOffer } from "@/graphql/generated";
import type { WithoutNulls } from "@/utils/withoutNulls";

export default function normalizeRenewalOffers(
  input: WithoutNulls<RenewalOffer[]>
) {
  return input.map((offer) => {
    const mostRecentNeedSomethingElseRequest = Array.isArray(
      offer.needSomethingElse
    )
      ? offer.needSomethingElse.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )[0]?.needs[0]
      : null;

    return {
      id: offer.id,
      workflowStatus: offer.workflowStatus,
      unit: {
        id: offer.lease?.unit?.id,
        name: offer.lease?.unit?.marketingName,
        building: offer.lease?.unit?.buildingName,
        communityName: offer.lease?.unit?.community.name,
      },
      // We need these items under determined status for sorting on mui grid
      determinedStatus: {
        lastViewedAt: offer.lastViewedAt,
        decisionType: offer.decision?.decision,
        decidedAt: offer.decision?.decidedAt,
        eSignature: offer.decision?.eSignature,
        mostRecentNeedSomethingElseRequest,
        workflowStatus: offer.workflowStatus,
        unitId: offer.lease?.unit?.id,
      },
      mostRecentNeedSomethingElseRequest,
      decision: {
        type: offer.decision?.decision,
        interestedTerm: offer.decision?.interestedTerm,
        decidedAt: offer.decision?.decidedAt,
        unitUpgrade: offer.unitUpgrade,
        moveOutInfo: {
          moveOutDate: offer.lease?.moveOutInfo?.moveOutDate,
        },
        eSignature: offer.decision?.eSignature,
        unit: {
          building: offer.lease?.unit?.buildingName,
        },
      },
      lease: {
        id: offer.lease?.id,
        noticeDate: offer.lease?.noticeDate,
        toDate: offer.lease?.toDate,
      },
      leaseToDate: offer.lease?.toDate,
      community: offer.lease?.unit?.community,
    };
  });
}

export type RenewalOffers = ReturnType<typeof normalizeRenewalOffers>;
