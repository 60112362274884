/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { ReactNode } from "react";
import { SelectChangeEvent } from "@mui/material";
import * as Styled from "./styles";

const FilterSelect = ({
  filterName,
  handleChange,
  filters,
  children,
  multiple = false,
  label,
}: {
  multiple?: boolean;
  handleChange:
    | ((event: SelectChangeEvent<unknown | string>, child: ReactNode) => void)
    | undefined;
  filterName: string;
  children?: JSX.Element[];
  filters: { [key: string]: string | number | undefined | (number | string)[] };
  label: string;
}) => (
  <Styled.Select
    useUpperLabel
    multiple={multiple}
    name={filterName}
    size="small"
    onChange={handleChange}
    label={label}
    value={filters?.[filterName] as string | number}
  >
    {children}
  </Styled.Select>
);
export default FilterSelect;
