import useCurrentUser from "@/graphql/hooks/user/useUser";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import useRenewalOfferBatches from "@/graphql/hooks/renewalOfferBatches/useRenewalOfferBatches";
import { RenewalOfferContext } from "@/context/renewal-offer/context";
import { SelectChangeEvent } from "@mui/material";
import useSegmentTrackEvent from "@/hooks/useSegmentTrackEvent";
import { buildDropDownFilterPayload } from "@/utils/buildDropDownFilterPayload";
import { Value } from "./types";

export default function useConnect() {
  const { setRenewalOfferState } = useContext(RenewalOfferContext);
  const router = useRouter();
  const { query, push, pathname } = router;
  const { data: user } = useCurrentUser();
  const { data: batches } = useRenewalOfferBatches();
  const { callTrack } = useSegmentTrackEvent();
  type Batch = typeof batches;
  type IndividualBatch = NonNullable<Batch>[number];
  const [batchesByCommunity, setBatchesByCommunity] = useState<
    { [key: string]: IndividualBatch[] } | undefined
  >();

  const [filters, setFilters] = useState<{
    community?: string;
    leaseEndDate?: string;
    batchId?: string;
  }>({
    community: { ...query }.community as string,
    leaseEndDate: { ...query }.leaseEndDate as string,
    batchId: { ...query }.batchId as string,
  });

  useEffect(() => {
    setRenewalOfferState({ batchId: { ...query }.batchId as string });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFilters((prevFilters) => {
      const community = prevFilters.community || user?.communities?.[0]?.name;
      const batchId =
        community &&
        (prevFilters.leaseEndDate
          ? batchesByCommunity?.[community].find(
              (date) => date.leaseEnd.label === prevFilters.leaseEndDate
            )?.id
          : batchesByCommunity?.[community][0].id);
      return {
        community,
        leaseEndDate:
          prevFilters.leaseEndDate ||
          (prevFilters.community &&
            batchesByCommunity?.[
              prevFilters.community || user?.communities?.[0]?.name || ""
            ]?.[0]?.leaseEnd.label),
        batchId,
      };
    });
  }, [user, batchesByCommunity]);

  useEffect(() => {
    if (batches) {
      setBatchesByCommunity(
        batches.reduce(
          (accumulator: { [key: string]: IndividualBatch[] }, batch) => {
            const state = accumulator;
            if (!state[batch.community]) {
              state[batch.community] = [];
            }
            state[batch.community].push(batch);
            return state;
          },
          {}
        )
      );
    }
  }, [batches]);

  useEffect(() => {
    // Initialize queryParams with the current query parameters from the URL
    const queryParams: { [key: string]: string | string[] | undefined } = {
      ...query,
    };

    const communityValue = filters.community;
    if (typeof communityValue === "string" && communityValue.trim() !== "") {
      queryParams.community = communityValue;
      // If the filter value is empty or not applicable, remove the key from queryParams
    } else {
      delete queryParams.community;
      delete queryParams.leaseEndDate;
    }

    const leaseEndDateValue = filters.leaseEndDate;
    if (
      typeof leaseEndDateValue === "string" &&
      leaseEndDateValue.trim() !== ""
    ) {
      queryParams.leaseEndDate = leaseEndDateValue;
      // If the filter value is empty or not applicable, remove the key from queryParams
    } else {
      delete queryParams.leaseEndDate;
    }

    const batchIdValue = filters.batchId;
    if (typeof batchIdValue === "string" && batchIdValue.trim() !== "") {
      queryParams.batchId = batchIdValue;
      // If the filter value is empty or not applicable, remove the key from queryParams
    } else {
      delete queryParams.leaseEndDate;
      delete queryParams.batchId;
    }

    // Update the URL with the new query parameters without triggering a full page reload
    void push(
      {
        pathname,
        query: queryParams,
      },
      undefined,
      { shallow: true }
    );
  }, [filters, pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const filterChangeTrackCall = (
    value: Value,
    filterChangeUpdate: {
      community: string | undefined;
      leaseEndDate: string | undefined;
      batchId: string | undefined;
    }
  ) => {
    const payloadConfig = {
      community: {
        filterName: "community",
        filterOptions: batchesByCommunity && Object.keys(batchesByCommunity),
        selectedFilters: [filterChangeUpdate.community],
      },
      leaseEnd: {
        filterName: "leaseEndDate",
        filterOptions:
          filterChangeUpdate.community &&
          batchesByCommunity?.[filterChangeUpdate.community].map(
            (batch) => batch.leaseEnd.label
          ),
        selectedFilters: [filterChangeUpdate.leaseEndDate],
      },
    };

    const selectedFilter = value.community ? "community" : "leaseEnd";

    const payload = buildDropDownFilterPayload({
      // when a user selects from the leaseEndDate dropdown, the selected value does not contain community
      selected: value.community || value.leaseEndDate,
      active: true,
      filterName: payloadConfig[selectedFilter].filterName,
      filterOptions: payloadConfig[selectedFilter].filterOptions,
      selectedFilters: payloadConfig[selectedFilter].selectedFilters,
      filterGroupName: "RMS Community Batch Selection",
      filterGroupState: {
        leaseEndDate: [filterChangeUpdate.leaseEndDate],
        community: [filterChangeUpdate.community],
      },
    });

    callTrack({ eventName: "filter.click", payload });
  };

  const handleChange = (e: SelectChangeEvent<unknown>) => {
    const value: Value = JSON.parse(e.target.value as string) as {
      id: string;
      community?: string;
      leaseEndDate?: string;
    };
    setRenewalOfferState({ batchId: value.id });

    const community = value.community || filters.community;
    const filterChangeUpdate = {
      ...filters,
      community,
      leaseEndDate: value.leaseEndDate,
      batchId:
        value.id || (community && batchesByCommunity?.[community]?.[0].id),
    };

    setFilters(filterChangeUpdate);
    filterChangeTrackCall(value, filterChangeUpdate);
  };
  return {
    setFilters,
    filters,
    batchesByCommunity,
    handleChange,
  };
}
