/* eslint-disable no-param-reassign */
import { useState, useMemo, useCallback, useContext, useEffect } from "react";
import { GridSortModel, GridRowParams } from "@mui/x-data-grid";
import { RenewalOfferWorkflowStatus } from "@/graphql/generated";
import useRenewalOffers from "@/graphql/hooks/renewalOffers/useRenewalOffers";
import { RenewalOfferContext } from "@/context/renewal-offer/context";
import useSegmentTrackEvent from "@/hooks/useSegmentTrackEvent";
import { useRouter } from "next/router";
import { ChipItemCountType, RowType } from "./types";
import { workflowStatusAndIconMap } from "./logic";

export default function useConnect() {
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "rating",
      sort: "desc",
    },
  ]);

  const { callTrack } = useSegmentTrackEvent();
  const { renewalOfferState } = useContext(RenewalOfferContext);
  const { push } = useRouter();
  const [batchId, setBatchId] = useState<string | undefined>(
    renewalOfferState?.batchId
  );

  useEffect(() => {
    setBatchId(renewalOfferState.batchId);
  }, [renewalOfferState]);

  const { data: rows, isLoading } = useRenewalOffers({
    batchId,
  });

  const groupedRows = useMemo(() => {
    const groups: { [key in RenewalOfferWorkflowStatus]: RowType[] } = {
      [RenewalOfferWorkflowStatus.InProgress]: [],
      [RenewalOfferWorkflowStatus.AwaitingDecision]: [],
      [RenewalOfferWorkflowStatus.Sent]: [],
      [RenewalOfferWorkflowStatus.Completed]: [],
    };

    (rows ?? []).forEach((row) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      const status = row.workflowStatus as RenewalOfferWorkflowStatus;
      if (status in groups) {
        groups[status]?.push(row);
      }
    });

    return groups;
  }, [rows]);

  const countItemsPerGivenStatusThatMeetCriteria = useCallback(
    (
      rowsGroupedByStatus: { [key in RenewalOfferWorkflowStatus]: RowType[] },
      actionItemCondition: (row: RowType) => boolean,
      needSomethingElseCondition: (row: RowType) => boolean
    ) => {
      const counts: {
        [key in RenewalOfferWorkflowStatus]:
          | {
              actionItemCount?: number;
              needSomethingElseCount?: number;
            }
          | undefined;
      } = {
        [RenewalOfferWorkflowStatus.InProgress]: {
          actionItemCount: 0,
          needSomethingElseCount: 0,
        },
        [RenewalOfferWorkflowStatus.AwaitingDecision]: {
          actionItemCount: 0,
          needSomethingElseCount: 0,
        },
        [RenewalOfferWorkflowStatus.Sent]: {
          actionItemCount: 0,
          needSomethingElseCount: 0,
        },
        [RenewalOfferWorkflowStatus.Completed]: undefined,
      };

      return Object.entries(rowsGroupedByStatus).reduce(
        (acc, [status, rowsGrouped]) => {
          if (status === RenewalOfferWorkflowStatus.Completed) return acc;

          acc[status as RenewalOfferWorkflowStatus] = rowsGrouped?.reduce(
            (counter, row: RowType) => ({
              actionItemCount:
                counter.actionItemCount + (actionItemCondition(row) ? 1 : 0),
              needSomethingElseCount:
                counter.needSomethingElseCount +
                (needSomethingElseCondition(row) ? 1 : 0),
            }),
            { actionItemCount: 0, needSomethingElseCount: 0 }
          ) ?? { actionItemCount: 0, needSomethingElseCount: 0 };
          return acc;
        },
        counts
      );
    },
    []
  );

  const chipItemCounts = useMemo<Record<string, ChipItemCountType>>(
    () =>
      countItemsPerGivenStatusThatMeetCriteria(
        groupedRows,
        (row: RowType) =>
          row.decision.eSignature?.status === "ALL_RESIDENTS_SIGNED",
        (row: RowType) =>
          typeof row.mostRecentNeedSomethingElseRequest === "string"
      ),
    [countItemsPerGivenStatusThatMeetCriteria, groupedRows]
  );

  const onAccordionChange = (
    expanded: boolean,
    status: RenewalOfferWorkflowStatus
  ) => {
    callTrack({
      eventName: expanded ? "table.opened" : "table.closed",
      payload: {
        tableName: "Renewal Offers",
        context: {
          batchId,
          status,
        },
      },
    });
  };
  const onRowClick = (params: GridRowParams<RowType>) => {
    void push(`/units/${params.row.unit?.id as string}`);
    const payload = {
      tableName: params.row.workflowStatus,
      rowType: "renewal_offer_record",
      row: params.row,
      context: { batchId: renewalOfferState.batchId },
    };
    callTrack({ eventName: "table_row.click", payload });
  };

  return {
    onAccordionChange,
    rows,
    isLoading,
    setSortModel,
    sortModel,
    workflowStatusAndIconMap,
    groupedRows,
    chipItemCounts,
    onRowClick,
  };
}
