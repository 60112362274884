/* eslint-disable import/prefer-default-export */
import { format } from "date-fns";

export const formatDate = (date: string | number | Date) =>
  format(
    new Date(date).toLocaleDateString([], {
      timeZone: "UTC",
    }),
    "MM/dd/yyyy"
  );
