type AnyObject = Record<string, unknown>;

const toSnakeCase = (str: string): string =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

const convertCamelCaseToSnakeCase = (obj: AnyObject): AnyObject => {
  const result: AnyObject = {};

  Object.entries(obj).forEach(([key, value]) => {
    const snakeKey = toSnakeCase(key);

    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      result[snakeKey] = convertCamelCaseToSnakeCase(value as AnyObject);
    } else {
      result[snakeKey] = value;
    }
  });

  return result;
};

export default convertCamelCaseToSnakeCase;
