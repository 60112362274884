import Image from "next/image";
import IconHeaderCTA from "@/components/IconHeaderCTA";
import searching from "@/assets/images/illustrations/searching.png";
import Loader from "@/components/Loader";
import { GridRowParams } from "@mui/x-data-grid";
import { RowType } from "./types";
import { columns } from "./config";
import { generateTableTitle } from "./logic";
import useConnect from "./connect";
import * as Styled from "./styles";

const TableComponent = ({
  selectedWorkflowStatusFilters,
}: {
  selectedWorkflowStatusFilters: Set<string>;
}) => {
  const {
    rows,
    isLoading,
    setSortModel,
    sortModel,
    workflowStatusAndIconMap,
    groupedRows,
    chipItemCounts,
    onAccordionChange,
    onRowClick,
  } = useConnect();
  const NoOffersFallbackCard = (
    <Styled.NoOffersCard
      content="There are no current offers"
      illustration={
        <Image
          src={searching.src}
          alt="No offers image"
          width={74}
          height={61}
        />
      }
    />
  );

  if (isLoading) {
    return <Loader />;
  }

  return !isLoading && rows && rows.length ? (
    workflowStatusAndIconMap.map(
      ({ workflowStatusFilter, workflowStatus, icon }) =>
        (selectedWorkflowStatusFilters.has("All renewals") ||
          selectedWorkflowStatusFilters.has(workflowStatusFilter)) &&
        icon && (
          <Styled.Grid
            onAccordionChange={(_e, expanded) =>
              onAccordionChange(expanded, workflowStatus)
            }
            key={workflowStatusFilter}
            tableTitle={generateTableTitle(
              icon,
              workflowStatusFilter +
                (!groupedRows[workflowStatus].length ? ` (no offers)` : ""),
              chipItemCounts[workflowStatus]?.actionItemCount,
              chipItemCounts[workflowStatus]?.needSomethingElseCount
            )}
            accordion
            accordionOpen
            data={{
              columns,
              rows: groupedRows[workflowStatus] || [],
            }}
            rowClassName={(params) =>
              `units-theme--${params.row.status as string}`
            }
            setSortModel={setSortModel}
            sortModel={sortModel}
            hidePagination={groupedRows[workflowStatus]?.length <= 100}
            noDataComponent={NoOffersFallbackCard}
            onRowClick={(params: GridRowParams<RowType>) => {
              onRowClick(params);
            }}
          />
        )
    )
  ) : (
    <IconHeaderCTA
      illustration={
        <Image
          src={searching.src}
          alt="No access image"
          width={241}
          height={200}
        />
      }
      header="There are no current offers"
    />
  );
};

export default TableComponent;
