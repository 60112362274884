import RENEWAL_OFFER_FRAGMENT from "@/graphql/fragments/renewalOffer";
import { RenewalOffersQuery } from "@/graphql/generated";
import { TypedDocumentNode, gql } from "@apollo/client";

const GET_RENEWAL_OFFERS: TypedDocumentNode<RenewalOffersQuery> = gql`
  query RenewalOffers($batchId: ID!) {
    renewalOffers(batchId: $batchId) {
      ...RenewalOffer
    }
  }
  ${RENEWAL_OFFER_FRAGMENT}
`;

export default GET_RENEWAL_OFFERS;
