import { RenewalOfferBatchesQuery } from "@/graphql/generated";
import { TypedDocumentNode, gql } from "@apollo/client";

const GET_RENEWAL_OFFER_BATCHES: TypedDocumentNode<RenewalOfferBatchesQuery> = gql`
  query renewalOfferBatches {
    renewalOfferBatches {
      ... on RenewalOfferBatchesPayload {
        renewalOfferBatches {
          __typename
          id
          community {
            __typename
            id
            name
          }
          month
          year
          renewalOfferPdfsAvailable
        }
      }
    }
  }
`;

export default GET_RENEWAL_OFFER_BATCHES;
