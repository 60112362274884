/* eslint-disable @typescript-eslint/no-floating-promises */
import { RenewalOffer } from "@/graphql/generated";
import { useEffect, useMemo } from "react";
import { useLazyQuery } from "@apollo/client";
import GET_RENEWAL_OFFERS from "@/graphql/queries/renewalOffers/getRenewalOfferBatches";
import { WithoutNulls, withoutNulls } from "@/utils/withoutNulls";
import normalizeRenewalOffers from "@/model/RenewalOffers";

export default function useRenewalOffers({ batchId }: { batchId?: string }) {
  const [getRenewalOffers, { data: raw, loading, error }] = useLazyQuery(
    GET_RENEWAL_OFFERS,
    {
      variables: {
        batchId,
      },
    }
  );

  const normalized = useMemo(() => {
    const data = withoutNulls(raw?.renewalOffers);
    return data && normalizeRenewalOffers(data as WithoutNulls<RenewalOffer[]>);
  }, [raw]);

  useEffect(() => {
    if (batchId && batchId !== "") {
      getRenewalOffers();
    }
  }, [getRenewalOffers, batchId]);

  return { data: normalized, isLoading: loading, error };
}
