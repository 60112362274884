import styled from "@emotion/styled";
import UtilizeSpacer from "@/styles/theme-styles";
import defaultTheme from "@/styles/themes/default";
import { css } from "@emotion/react";
import Button from "../Button";

import { Body1 } from "../Typography";

export const Card = styled.div<{ $shadow?: boolean }>`
  background-color: ${defaultTheme.renewTheme.colors.white};
  border-radius: 8px;
  display: flex;
  max-width: 743px;

  ${UtilizeSpacer({
    options: [
      {
        size: "2xs",
        direction: [1, 2],
      },
    ],
    type: "padding",
  })}

  ${({ $shadow }) =>
    $shadow &&
    css`
      box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    `}
`;

export const Right = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: space-evenly;
  ${UtilizeSpacer({
    specificOption: {
      size: "md",
      direction: "left",
    },
    type: "padding",
  })}
`;

export const Content = styled(Body1)`
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
`;

export const ButtonWrapper = styled.div`
  background-color: ${defaultTheme.renewTheme.colors.white};
  display: flex;
  border: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const ReportButton = styled(Button)`
  ${UtilizeSpacer({
    options: [
      {
        size: "md",
        direction: [1, 3],
      },
      {
        size: "3xs",
        direction: [0, 2],
      },
    ],
    type: "padding",
  })}
`;
