const Sent = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="auto"
    height="auto"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.21986 19.2199C7.94444 19.2199 6.74586 18.9778 5.62411 18.4938C4.50236 18.0098 3.5266 17.3528 2.69681 16.5231C1.86702 15.6933 1.21011 14.7175 0.726064 13.5958C0.242021 12.474 0 11.2754 0 10C0 8.7246 0.242021 7.52601 0.726064 6.40426C1.21011 5.28252 1.86702 4.30675 2.69681 3.47696C3.5266 2.64717 4.50236 1.99026 5.62411 1.50622C6.74586 1.02217 7.94444 0.780151 9.21986 0.780151C10.4953 0.780151 11.6939 1.02217 12.8156 1.50622C13.9374 1.99026 14.9131 2.64717 15.7429 3.47696C16.5727 4.30675 17.2296 5.28252 17.7137 6.40426C18.1977 7.52601 18.4397 8.7246 18.4397 10C18.4397 11.2754 18.1977 12.474 17.7137 13.5958C17.2296 14.7175 16.5727 15.6933 15.7429 16.5231C14.9131 17.3528 13.9374 18.0098 12.8156 18.4938C11.6939 18.9778 10.4953 19.2199 9.21986 19.2199ZM9.21986 17.3759C11.279 17.3759 13.023 16.6614 14.4521 15.2323C15.8812 13.8032 16.5957 12.0591 16.5957 10C16.5957 9.01656 16.4113 8.07152 16.0426 7.1649C15.6738 6.25828 15.1436 5.45923 14.4521 4.76774L9.21986 10V2.62412C7.16076 2.62412 5.41667 3.33866 3.98759 4.76774C2.55851 6.19682 1.84397 7.94091 1.84397 10C1.84397 12.0591 2.55851 13.8032 3.98759 15.2323C5.41667 16.6614 7.16076 17.3759 9.21986 17.3759Z"
      fill="#B14646"
    />
  </svg>
);
export default Sent;
