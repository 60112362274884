export type DropdownFilterPayload = {
  selected: string | undefined;
  active: boolean;
  filterName: string;
  filterOptions: "" | string[] | undefined;
  selectedFilters: (string | undefined)[];
  filterGroupName?: string;
  filterGroupState?: {
    [key: string]: (string | undefined)[];
  };
};

export const buildDropDownFilterPayload = ({
  selected,
  active = true,
  filterName,
  filterOptions,
  selectedFilters,
  filterGroupName,
  filterGroupState,
}: DropdownFilterPayload) => {
  const payload = {
    selected,
    active,
    filterName,
    filterOptions,
    elementType: "dropdown",
    selectedFilters,
    filterGroup: Boolean(filterGroupName) && Boolean(filterGroupState),
    filterGroupName,
    filterGroupState,
  };

  return payload;
};
