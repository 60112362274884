import { gql, TypedDocumentNode } from "@apollo/client";
import { RenewalOfferFragment } from "../generated";

const RENEWAL_OFFER_FRAGMENT: TypedDocumentNode<RenewalOfferFragment> = gql`
  fragment RenewalOffer on RenewalOffer {
    id
    createdAt
    lastViewedAt
    workflowStatus
    needSomethingElse {
      createdAt
      needs
    }
    unitUpgrade {
      id
      createdAt
      unit {
        id
        marketingName
        buildingName
      }
    }
    decision {
      id
      decision
      decidedAt
      eSignature {
        id
        status
        type
      }
      interestedTerm {
        id
        lengthInMonths
        baseRent
      }
    }
    lease {
      id
      noticeDate
      toDate
      moveOutInfo {
        moveOutDate
      }
      unit {
        id
        marketingName
        buildingName
        community {
          id
          name
        }
      }
    }
  }
`;

export default RENEWAL_OFFER_FRAGMENT;
