/* eslint-disable import/no-extraneous-dependencies */

/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import { RenewalOfferWorkflowStatus } from "@/graphql/generated";
import InProgress from "@/assets/images/svgs/InProgress";
import AwaitingDecision from "@/assets/images/svgs/AwaitingDecision";
import * as Styled from "./styles";
import {
  NeedSomethingElseRequestCopyConfigType,
  RequestValueType,
} from "./types";

export const workflowStatusAndIconMap = [
  {
    workflowStatusFilter: "All renewals",
    workflowStatus: null,
    icon: null,
  },
  {
    workflowStatusFilter: "In progress",
    workflowStatus: RenewalOfferWorkflowStatus.InProgress,
    icon: <InProgress />,
  },
  {
    workflowStatusFilter: "Awaiting Decision",
    workflowStatus: RenewalOfferWorkflowStatus.AwaitingDecision,
    icon: <AwaitingDecision />,
  },
  {
    workflowStatusFilter: "Sent",
    workflowStatus: RenewalOfferWorkflowStatus.Sent,
    icon: <Styled.SentIcon />,
  },
  {
    workflowStatusFilter: "Complete",
    workflowStatus: RenewalOfferWorkflowStatus.Completed,
    icon: <Styled.CompleteIcon />,
  },
];

export const generateTableTitle = (
  icon: React.ReactNode,
  text: string,
  actionItemCount?: number,
  residentMessageCount?: number
) => (
  <>
    {icon} {text}
    {actionItemCount && actionItemCount > 0 ? (
      <Styled.Highlight $size="2xs" $type="actions">
        {actionItemCount} action item{actionItemCount === 1 ? "" : "s"}
      </Styled.Highlight>
    ) : (
      ""
    )}
    {residentMessageCount && residentMessageCount > 0 ? (
      <Styled.Highlight $size="2xs" $type="messages">
        {residentMessageCount} resident message
        {residentMessageCount === 1 ? "" : "s"}
      </Styled.Highlight>
    ) : (
      ""
    )}
  </>
);

const needSomethingElseRequestCopyConfig: NeedSomethingElseRequestCopyConfigType[] =
  [
    {
      requestValue: RequestValueType.DifferentUnit,
      copy: "Resident requesting different unit",
    },
    {
      requestValue: RequestValueType.Budget,
      copy: "Resident has budget question",
    },
  ];

export const determineNeedSomethingElseCopy = (
  requestValue: string
): string => {
  if (requestValue.startsWith("differentTerm")) {
    return "Resident requesting different term";
  }

  const copyConfigItem = needSomethingElseRequestCopyConfig.find(
    (item) => item.requestValue === requestValue
  );

  return copyConfigItem
    ? copyConfigItem.copy
    : "Resident has a general question";
};
