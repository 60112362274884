import {
  Accordion as AccordionDefault,
  AccordionSummary as AS,
} from "@mui/material";
import defaultTheme from "@/styles/themes/default";
import ArrowDown from "@mui/icons-material/KeyboardArrowUp";
import styled from "styled-components";
import UtilizeSpacer from "@/styles/theme-styles";

export const Accordion = styled(AccordionDefault)`
  &.MuiAccordion-root {
    background-color: transparent;
    box-shadow: none;
    width: 100%;
    ${UtilizeSpacer({
      specificOption: {
        size: "xs",
        direction: "bottom",
      },
      type: "margin",
    })}
    .MuiAccordionSummary-content {
      margin: 0;
    }
  }
  .MuiAccordion-region .MuiAccordionDetails-root,
  .MuiButtonBase-root.MuiAccordionSummary-root {
    padding: 0;
  }
`;

export const ArrowIcon = styled(ArrowDown)`
  color: ${defaultTheme.renewTheme.colors.black};
  height: 1.125rem;
  margin: auto auto auto 0;
  vertical-align: bottom;
  transition: transform 100ms ease-in;
  width: 1.125rem;
  margin-left: 8px;
`;

export const AccordionSummary = styled(AS)`
  padding: 0;

  &.MuiAccordionSummary-root {
    margin: auto 0;
    background-color: transparent;
    &.Mui-expanded {
      min-height: 0;
      ${ArrowIcon} {
        transform: rotate(180deg);
      }
    }
    &.Mui-disabled {
      opacity: 1;
    }
  }
`;
