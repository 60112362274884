import { RenewalOffers } from "@/model/RenewalOffers";

export type Filters = {
  leaseEndDate?: number;
  unit?: string;
  community?: string;
  renewalStatus?: string;
};

// Utility type to extract the type of an element from an array type
export type ElementType<T extends readonly unknown[]> =
  T extends readonly (infer U)[] ? U : never;

// Define the type for a single member of rows
export type RowType = ElementType<NonNullable<RenewalOffers>>;

export enum RequestValueType {
  DifferentUnit = "differentUnit",
  Budget = "budget",
}

export type NeedSomethingElseRequestCopyConfigType = {
  requestValue: RequestValueType | `differentTerm${string}`;
  copy: string;
};

export type ChipItemCountType =
  | {
      actionItemCount?: number;
      needSomethingElseCount?: number;
    }
  | undefined;
