import { ReactNode } from "react";
import * as Styled from "./styles";

export default function Card({
  content,
  subContent,
  link,
  illustration,
  linkComponent,
  className,
  hasShadow,
}: {
  content: string;
  subContent?: string;
  link?: { href: string; label?: string };
  illustration?: ReactNode;
  linkComponent?: ReactNode;
  className?: string;
  hasShadow?: boolean;
}) {
  return (
    <Styled.Card className={className} $shadow={hasShadow}>
      {illustration && illustration}
      <Styled.Right>
        <div>
          <Styled.Content $size="lg" $weight="bold">
            {content}
          </Styled.Content>
          {subContent && (
            <Styled.Content $size="sm">{subContent}</Styled.Content>
          )}
        </div>
        {linkComponent && linkComponent}
        {link && (
          <Styled.ButtonWrapper>
            <Styled.ReportButton href={link.href}>
              {link.label || "View report"}
            </Styled.ReportButton>
          </Styled.ButtonWrapper>
        )}
      </Styled.Right>
    </Styled.Card>
  );
}
