/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Tooltip } from "@mui/material";
import { useState } from "react";

import * as Styled from "./styles";

const ToolTip = ({
  popperContent,
  className,
  children,
}: {
  popperContent: string;
  className?: string;
  children: JSX.Element;
}): JSX.Element => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <Styled.ToolTipWrapper
      className={className}
      onMouseOver={() => setShowTooltip(true)}
      onFocus={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <Tooltip
        PopperProps={{
          sx: Styled.tooltipStyles,
        }}
        title={popperContent}
        open={showTooltip}
        onClick={() => {
          showTooltip ? setShowTooltip(true) : setShowTooltip(false);
        }}
      >
        {children}
      </Tooltip>
    </Styled.ToolTipWrapper>
  );
};

export default ToolTip;
