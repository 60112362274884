import {
  FormControl,
  FormHelperText,
  SelectChangeEvent // eslint-disable-line
} from "@mui/material";
import { ReactNode } from "react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import * as Styled from "./styles";

export default function SingleSelect({
  value,
  name,
  onChange,
  children,
  label,
  useUpperLabel = false,
  className,
  fullWidth,
  required,
  error,
  size,
  multiple,
}: {
  value?: number | "" | undefined | string | string[] | number[];
  name?: string;
  onChange:
    | ((event: SelectChangeEvent<unknown | string>, child: ReactNode) => void)
    | undefined;
  children: ReactNode;
  label?: string;
  className?: string;
  fullWidth?: boolean;
  required?: boolean;
  error?: {
    error?: boolean;
    errorText?: string;
  };
  size?: "small";
  multiple?: boolean;
  useUpperLabel?: boolean;
}) {
  return (
    <FormControl fullWidth={fullWidth} error={error?.error} size={size}>
      {useUpperLabel && label && (
        <Styled.Label id={`${label}-select-label`}>{label}</Styled.Label>
      )}
      <Styled.Select
        renderValue={() => (
          <span style={{ color: "#475467" }}>
            {useUpperLabel ? value : label}
          </span>
        )}
        displayEmpty
        IconComponent={KeyboardArrowDownOutlinedIcon}
        className={className}
        fullWidth={fullWidth}
        labelId={label && `${label}-select-label`}
        id="community-select"
        required={required}
        value={value}
        multiple={multiple}
        data-name={name}
        label={useUpperLabel ? label : undefined}
        onChange={onChange}
      >
        {children}
      </Styled.Select>
      {error?.error && <FormHelperText>{error?.errorText}</FormHelperText>}
    </FormControl>
  );
}
