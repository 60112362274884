import convertCamelCaseToSnakeCase from "@/utils/convertCamelCaseToSnakeCase";
import { useCallback, useMemo } from "react";

import useUser from "@/graphql/hooks/user/useUser";
import { useAnalytics } from "@/providers/AnalyticsProvider";
import { Props } from "./types";

const useSegmentTrackEvent = (): {
  callTrack: ({ eventName, payload }: Props) => void;
} => {
  const { data: currentUser } = useUser();
  const analytics = useAnalytics();

  const defaultPayload = useMemo(() => {
    if (!currentUser) return {};
    return {
      user: {
        id: currentUser.id,
        email: currentUser.email,
        role: currentUser.role,
        propertyManager: currentUser.propertyManager,
        enabled_features: currentUser?.enabledFeatures || [],
      },
      _meta: {
        renew_event_version: 1.0,
      },
    };
  }, [currentUser]);

  const callTrack = useCallback(
    ({ eventName, payload }: Props) => {
      const params = {
        ...defaultPayload,
        data: convertCamelCaseToSnakeCase(payload),
      };
      if (analytics && currentUser) {
        analytics.track(`partner_app.${eventName}`, params).catch((error) => {
          console.error("Error tracking event", error);
        });
      }
    },
    [currentUser, defaultPayload, analytics]
  );
  return { callTrack };
};

export default useSegmentTrackEvent;
