import { MenuItem } from "@mui/material";

const getFilterData = (
  batchesByCommunity: {
    [key: string]: {
      id: string;
      leaseEnd: {
        label: string;
        date: string;
      };
      month: string;
      year: string;
      community: string;
      renewalOfferPdfsAvailable: boolean;
    }[];
  },
  community: string
): {
  label: string;
  multiple?: boolean;
  filterName: "leaseEndDate" | "community";
  children?: JSX.Element[];
}[] => [
  {
    label: "Community",
    multiple: false,
    filterName: "community",
    children: [...Object.keys(batchesByCommunity)]
      .sort((a, b) => a.localeCompare(b))
      .map((communityfilterItem) => {
        const value = {
          id: batchesByCommunity[communityfilterItem][0].id,
          community: communityfilterItem,
          leaseEndDate:
            batchesByCommunity[communityfilterItem][0].leaseEnd.label,
        };
        return (
          <MenuItem key={communityfilterItem} value={JSON.stringify(value)}>
            {communityfilterItem}
          </MenuItem>
        );
      }),
  },
  {
    label: "Lease end",
    multiple: false,
    filterName: "leaseEndDate",
    children: batchesByCommunity[community].map((batch) => {
      const value = {
        id: batch.id,
        leaseEndDate: batch.leaseEnd.label,
      };
      return (
        <MenuItem key={batch.id} value={JSON.stringify(value)}>
          {batch.leaseEnd.label}
        </MenuItem>
      );
    }),
  },
];

export default getFilterData;
