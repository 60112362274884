import styled from "@emotion/styled";

import { Select as DefaultSelect, InputLabel } from "@mui/material";
import defaultTheme from "@/styles/themes/default";

export const Select = styled(DefaultSelect)`
  border-radius: 16px;
  border-color: red;

  background-color: ${defaultTheme.renewTheme.colors.white};
  .MuiOutlinedInput-notchedOutline {
    border-color: ${defaultTheme.renewTheme.colors.night400} !important;
  }
  * {
    color: ${defaultTheme.renewTheme.colors.black} !important;
  }
`;

export const Label = styled(InputLabel)`
  color: ${defaultTheme.renewTheme.colors.night400};
  &.Mui-focused {
    color: ${defaultTheme.renewTheme.colors.night400};
  }
`;
