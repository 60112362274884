import { AccordionDetails } from "@mui/material";
import { memo } from "react";

import * as Styled from "./styles";
import type { Props } from "./types";

function Accordion({
  children,
  disabled,
  expanded,
  ariaControls,
  accordionId,
  summary,
  className,
  onChange,
}: Props): JSX.Element {
  return (
    <Styled.Accordion
      className={className}
      disabled={disabled}
      defaultExpanded={expanded}
      onChange={onChange}
    >
      <Styled.AccordionSummary aria-controls={ariaControls} id={accordionId}>
        {summary}
        {!disabled && <Styled.ArrowIcon />}
      </Styled.AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Styled.Accordion>
  );
}

export default memo(Accordion);
