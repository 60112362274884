import DropdownFilters from "./partials/DropdownFilters";
import * as Styled from "./styles";
import useConnect from "./connect";
import StatusTables from "./partials/StatusTables";

const RenewalOffers = () => {
  const {
    selectedWorkflowStatusFilters,
    handleWorkflowStatusFilterClick,
    isWorkflowStatusFilterActive,
    workflowStatusAndIconMap,
  } = useConnect();

  return (
    <Styled.Container>
      <Styled.Header title="Renewal Offers">
        {workflowStatusAndIconMap.map(({ workflowStatusFilter }) => (
          <Styled.FilterItem
            key={workflowStatusFilter}
            label={workflowStatusFilter}
            small
            variant={
              isWorkflowStatusFilterActive(workflowStatusFilter)
                ? "filled"
                : "outlined"
            }
            onClick={() =>
              handleWorkflowStatusFilterClick(workflowStatusFilter)
            }
          />
        ))}
      </Styled.Header>
      <DropdownFilters />
      <StatusTables
        selectedWorkflowStatusFilters={selectedWorkflowStatusFilters}
      />
    </Styled.Container>
  );
};

export default RenewalOffers;
