import styled from "@emotion/styled";
import { Chip } from "@mui/material";
import defaultTheme from "@/styles/themes/default";

export const Button = styled(Chip)<{ $filled: boolean; $small?: boolean }>`
  ${({ $filled }) =>
    $filled
      ? `
  background-color: ${defaultTheme.renewTheme.colors.night400};
  color: ${defaultTheme.renewTheme.colors.white};
  `
      : `
  background-color: transparent;
  color: ${defaultTheme.renewTheme.colors.night400};
  border: 1px solid ${defaultTheme.renewTheme.colors.night400};
  `}

  ${({ $small }) =>
    $small &&
    `
 font-size: 0.625rem;
  height: 24px;
  `}
`;
