import FilterSelect from "@/components/FilterSelectV2";
import useConnect from "./connect";
import getFilterData from "./logic";
import * as Styled from "./styles";

const DropdownFilters = () => {
  const { batchesByCommunity, filters, handleChange } = useConnect();
  return (
    <Styled.FilterWrapper>
      <div>
        {batchesByCommunity &&
          filters.community &&
          filters.batchId &&
          getFilterData(batchesByCommunity, filters.community).map((filter) => (
            <FilterSelect
              handleChange={(e) => handleChange(e)}
              key={filter.label}
              filterName={filter.filterName}
              filters={filters}
              label={filter.label}
            >
              {filter.children}
            </FilterSelect>
          ))}
      </div>
    </Styled.FilterWrapper>
  );
};

export default DropdownFilters;
